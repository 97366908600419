.variants {
    display: flex;
    margin-top: 20px;
    background: #FAF9F9;
    border-radius: 5px;
}

@media (max-width: 767px) {
    .variants {
        margin-top: 10px;
    }
}

.variant {
    width: 97px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #929292;
    cursor: pointer;
}

@media (max-width: 767px) {
    .variant {
        width: 94px;
        height: 30px;
        font-size: 14px;
    }
}

.variant.active {
    background: #D42216;
    border-radius: 5px;
    color: #FFFFFF;
}