.container {
    width: 520px;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 20px;
    margin-top: 20px;
}

@media (max-width: 767px) {
    .container {
        width: 100%;
        column-gap: 5px;
        row-gap: 10px;
        margin-top: 10px;
    }
}

.input {
    padding: 21px;
    background: #FFFFFF;
    border-radius: 5px;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #282828;
    border: 0;
    outline: 0;
    box-sizing: border-box;
}

@media (max-width: 767px) {
    .input {
        padding: 12px;
        font-size: 14px;
        line-height: 16px;
    }
}

.input::placeholder {
    color: #929292;
}

.inputStreet {
    composes: input;
    width: 375px;
    height: 65px;
}

.inputHouse {
    composes: input;
    width: 135px;
    height: 65px;
}

.inputEntrance {
    composes: input;
    width: 190px;
    height: 65px;
}

.inputFlat {
    composes: input;
    width: 175px;
    height: 65px;
}

.inputComment {
    composes: input;
    width: 520px;
    height: 180px;
}

@media (max-width: 767px) {
    .inputStreet {
        width: 202px;
        height: 40px;
        flex-grow: 1;
    }
    .inputHouse {
        width: 73px;
        height: 40px;
    }
    .inputEntrance {
        width: 103px;
        height: 40px;
    }
    .inputFlat {
        width: 94px;
        height: 40px;
    }
    .inputComment {
        width: 280px;
        height: 100px;
        flex-grow: 1;
    }
}