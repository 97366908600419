.product {
    position: relative;
    padding-bottom: 50px;
    width: 280px;
}

.product img {
    border-radius: 5px;
    width: 135px;
    height: 105px;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0 ;
}

.titleRow {
    margin-left: 145px;
    margin-right: -12px;
}

.title {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: white;
    display: inline-block;
}

.portion {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #929292;
    display: inline-block;
}

.composition {
    margin-top: 20px;
    margin-left: 145px;
}

.compositionHeader {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}

.compositionText {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}

.nextLink {
    color: #D42216;
    cursor: pointer;
}

.orderRow {
    position: absolute;
    bottom: 0;
    display: flex;
    background: white;
    border-radius: 5px;
}

.orderButton {
    user-select: none;

    background: #D42216;
    border-radius: 5px;
    width: 135px;
    height: 40px;

    font-family: 'Ubuntu', "Segoe UI", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
}

.orderButton.active {
    background: #FFD3D3;
    border-radius: 5px 5px 5px 5px;
    color: #D42216;
}

.cartCount {
    width: 145px;
    height: 40px;
    background: #FBFBFB;
    border-radius: 0px 5px 5px 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #282828;

    column-gap: 12px;
    user-select: none;
}

.cartCountButton {
    height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px;
}

.saleBg {
    position: absolute;
    width: 135px;
    height: 20px;
    left: 0;
    top: 85px;

    background: rgba(212, 34, 22, 0.8);
    backdrop-filter: blur(4px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 0px 0px 5px 3px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.saleText {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
}