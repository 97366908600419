.layout {
    padding-bottom: calc(80px + 434px);
    position: relative;
    min-height: 100vh;
    box-sizing: border-box;
    background: #161616;
}

@media (max-width: 767px) {
    .layout {
        padding-bottom: 0;
    }
}

.swiper {
    min-height: 200px;
}

.swiperSlide {
    min-height: 400px;
    min-width: 280px;
}