.wrapper {
    height: 100%;
    overflow-y: auto;
    background: #FAF9F9;
    width: 600px;
}

@media (max-width: 767px) {
    .wrapper {
        width: 100vw;
        margin-top: 60px;
    }
}

.selectTime {
    padding: 40px;
    display: flex;
    flex-direction: column;
    background: #FAF9F9;
    position: relative;
    box-sizing: border-box;
    min-height: 100%;
}

@media (max-width: 767px) {
    .selectTime {
        padding: 20px;
    }
}

.closeImg {
    position: absolute;
    right: 40px;
    top: 49px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

@media (max-width: 767px) {
    .closeImg {
        display: none;
    }
}

.closeImg:hover {
    opacity: 0.8;
}

.header {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 37px;
    color: #282828;
    margin-bottom: 50px;
}

@media (max-width: 767px) {
    .header {
        font-size: 20px;
        line-height: 23px;
        margin-bottom: 20px;
    }
}

.divider {
    box-sizing: border-box;
    height: 1px;
    opacity: 0.3;
    background: #929292;
}

@media (max-width: 767px) {
    .divider {
        display: none;
    }
}

.formRow {
    display: flex;
    column-gap: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
}

@media (max-width: 767px) {
    .formRow {
        flex-direction: column;
        margin-top: 0;
        margin-bottom: 20px;
    }
    .formRowBottom {
        flex-direction: row;
        margin-top: 0;
        column-gap: 10px;
    }
}

.selectButton {
    padding: 21px;
    background: #FFFFFF;
    border-radius: 5px;

    width: 250px;
    height: 165px;
    font-family: 'Ubuntu', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #282828;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

@media (max-width: 767px) {
    .selectButton {
        width: 100%;
    }
}

.selectButton img {
    filter: brightness(0%);
    margin-bottom: 14px;
}

.selectButtonSelected {
    composes: selectButton;
    color: #D42216;
    border: 1px solid #D42216;
    border-radius: 5px 5px 5px 5px;
}

.selectButtonSelected img {
    filter: none;
}

.button {
    width: 440px;
    height: 69px;
    background: #D42216;
    border-radius: 5px;
    margin: 0 auto;
    cursor: pointer;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
}

@media (max-width: 767px) {
    .button {
        position: static;
        margin-top: auto;
        width: 100%;
        height: 40px;
        font-size: 16px;
        line-height: 18px;
        column-gap: 10px;
    }
}

.button:hover {
    background: #FA3D3D;
}

.todayLabel {
    width: 250px;
    height: 65px;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 21px;
    box-sizing: border-box;

    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #282828;
}

@media (max-width: 767px) {
    .todayLabel {
        width: 111px;
        height: 40px;
        font-size: 14px;
        line-height: 16px;
        padding: 12px;
    }
}

.select {
    composes: todayLabel;
    cursor: pointer;
    position: relative;
    outline: 0;
    border: 0;
    text-align: left;
    margin-bottom: 220px;
}

@media (max-width: 767px) {
    .select {
        margin-bottom: 0;
        width: auto;
        flex-grow: 1;
        height: 40px;
        font-size: 14px;
        line-height: 16px;
        padding: 12px;
    }
}

.selectActive {
    composes: select;
    color: #D42216;
}

.selectMark {
    position: absolute;
    top: 29px;
    right: 28px;
}

@media (max-width: 767px) {
    .selectMark {
        top: 16px;
        right: 12px;
    }
}

.selectMarkRotated {
    composes: selectMark;
    transform: rotate(180deg);
}

.selectMenu {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 21px 24px;
    column-gap: 16px;
    row-gap: 21px;
}

@media (max-width: 767px) {
    .selectMenu {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        column-gap: 12px;
        row-gap: 12px;
        width: 280px;
        box-sizing: border-box;
        margin: 0 auto;
        position: relative;
    }
}

.selectMenuItem {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #282828;
    cursor: pointer;
}

@media (max-width: 767px) {
    .selectMenuItem {
        font-size: 14px;
        line-height: 16px;
    }
}

.selectMenuItemActive {
    composes: selectMenuItem;
    color: #D42216;
}

.verticalDivider {
    width: 1px;
    position: absolute;
    top: 21px;
    bottom: 21px;
    opacity: 0.3;
    background: #929292;
}

.verticalDivider1 {
    composes: verticalDivider;
    left: 73px;
}

.verticalDivider2 {
    composes: verticalDivider;
    left: 130px;
}

.verticalDivider3 {
    composes: verticalDivider;
    left: 186px;
}

.verticalDivider4 {
    composes: verticalDivider;
    left: 212px;
    display: none;
}

@media (max-width: 767px) {
    .verticalDivider1 {
        left: 68px;
    }
    .verticalDivider2 {
        left: 116px;
    }
    .verticalDivider3 {
        left: 164px;
    }
    .verticalDivider4 {
        display: block;
    }
}