.item {
    font-family: 'Open Sans', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    color: white;
    text-decoration: none;
    cursor: pointer;
}

@media (max-width: 1365px) {
    .item {
        padding: 4px 10px;
        background: #2C2C2C;
        border-radius: 5px;
        flex-shrink: 0;
        font-size: 16px;
        line-height: 22px;
    }
}

.item.active {
    color: #D42216;
}

.item:active {
    color: #D42216;
}

@media (hover: hover) {
    .item:hover {
        color: #D42216;
    }
}

.line {
    height: 2px;
    background: #D42216;
    width: 60%;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 1365px) {
    .line {
        display: none;
    }
}