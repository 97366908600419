.product {
    position: relative;
    min-height: calc(508px - 92px);
    padding-bottom: 92px;
}

.product img {
    width: 100%;
    height: 205px;
    object-fit: cover;
    border-radius: 5px;
}

.titleRow {
    margin-top: 10px;
    margin-right: -2px;
}

.title {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 25px;
    color: white;
}

.portion {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 25px;
    color: #929292;
    margin-left: 10px;
}

.composition {
    margin-top: 20px;
    color: white;
}

@media (max-width: 1365px) {
    .composition {
        margin-top: 10px;
        margin-left: 145px;
        min-height: 77px;
    }
}

.compositionHeader {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}

.compositionText {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}

@media (max-width: 1365px) {
    .compositionText {
        font-weight: 400;
        font-size: 10px;
        line-height: 13.62px;
        display: block;
    }
}

.nextLink {
    color: #D42216;
    cursor: pointer;
}

.orderRow {
    position: absolute;
    bottom: 0;
    display: flex;
    background-color: white;
    border-radius: 5px;
}

.orderButton {
    user-select: none;

    background: #D42216;
    border-radius: 5px;
    width: 174px;
    height: 62px;

    font-family: 'Ubuntu', "Segoe UI", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 31px;
    line-height: 100%;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
}

.orderButton:hover {
    background: #FA3D3D;
}

.orderButton.active {
    background: #FFD3D3;
    border-radius: 5px 5px 5px 5px;
    color: #D42216;
}

.cartCount {
    width: 116px;
    height: 62px;
    background: #FBFBFB;
    border-radius: 0px 5px 5px 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #282828;

    user-select: none;
}

.cartCountButton {
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.cartCountButton:hover {
    opacity: 0.7;
}

.saleBg {
    position: absolute;
    width: 193px;
    height: 33px;
    right: 0;
    top: 20px;

    background: rgba(212, 34, 22, 0.8);
    backdrop-filter: blur(4px);
    border-radius: 5px 0px 0px 5px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.saleText {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
}