.container {
    position: sticky;
    width: 100%;
    height: 382px;
    bottom: 0;
    margin-top: auto;

    background: #FFFFFF;
    box-shadow: 0 -6px 10px rgba(184, 184, 184, 0.1);
    border-radius: 5px;
    padding: 40px;
    box-sizing: border-box;
}

@media (max-width: 767px) {
    .container {
        padding: 20px;
        height: 264px;
    }
}

.promoInputRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.promoInput, .promoInput::placeholder {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #929292;
    border: none;
    outline: 0;
    padding: 0;
}


.promoApply {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #D42216;
    cursor: pointer;
}

.promoApply:hover {
    color: #FA3D3D;
}

.promoNotExists {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    color: #D42216;
}

@media (max-width: 767px) {
    .promoInput, .promoApply, .promoNotExists, .promoInput::placeholder {
        font-size: 14px;
        line-height: 16px;
        max-width: 180px;
    }
    .promoNotExists {
        text-align: right;
    }
}

.divider {
    box-sizing: border-box;
    height: 1px;
    opacity: 0.3;
    background: #929292;
}

.infoRow {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

.infoTitle {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #929292;
}

.infoValue {
    font-family: 'Ubuntu', "Segoe UI";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #282828;
}

@media (max-width: 767px) {
    .infoTitle, .infoValue {
        font-size: 14px;
        line-height: 16px;
    }
}

.button {
    width: 440px;
    height: 69px;
    background: #D42216;
    border-radius: 5px;
    margin: 40px;
    cursor: pointer;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
}

@media (max-width: 767px) {
    .button {
        width: auto;
        height: 40px;
        margin: 20px auto 0 auto;
        font-size: 16px;
        line-height: 18px;
    }
}

.button:hover {
    background: #FA3D3D;
}