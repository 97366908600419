.wrapper {
    height: 100%;
    overflow-y: auto;
    width: 600px;
}

.cart {
    display: flex;
    flex-direction: column;
    background: #FAF9F9;
    position: relative;
}

.closeImg {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 40px;
    top: 49px;
    cursor: pointer;
}

.closeImg:hover {
    opacity: 0.8;
}

.header {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 37px;
    color: #282828;
    margin-left: 40px;
    margin-top: 40px;
}

@media (max-width: 767px) {
    .header {
        font-size: 20px;
        line-height: 23px;
        margin-left: 20px;
        margin-top: 20px;
    }
}

.header .cost {
    color: #D42216;
}