.text-info {
    /* Информация */
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    color: white;
}

@media (max-width: 767px) {
    .MuiDialogTitle-root {
        padding-top: 20px !important;
        padding-left: 20px !important;
        padding-bottom: 0 !important;
    }
    .MuiDialogContent-root {
        padding-top: 20px !important;
        padding-left: 20px !important;
    }
}