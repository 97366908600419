.cart {
    width: 276px;
    height: 86px;
    box-sizing: border-box;

    position: absolute;
    top: 0;
    right: 0;

    background: #D42216;

    cursor: pointer;
    user-select: none;

    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1365px) {
    .cart {
        display: none;
    }
}

.cart:hover {
    background: #FA3D3D;
}

.cart.empty {
    background: #282828;
}

.text {
    margin-left: 16px;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
}

.image {

}

.positions {
    position: absolute;
    left: 18.84%;
    top: 49%;

    width: 18px;
    height: 18px;

    background: url("../../images/ellips.svg");
}

.positionsText {
    font-family: 'Ubuntu', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #D42216;
    transform: rotate(0.24deg);

    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

