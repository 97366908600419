.wrapper {
    height: 100%;
    overflow-y: auto;
    background: #FAF9F9;
    width: 600px;
}

@media (max-width: 767px) {
    .wrapper {
        width: 100vw;
        margin-top: 60px;
    }
}

.cart {
    padding: 40px;
    display: flex;
    flex-direction: column;
    background: #FAF9F9;
    position: relative;
    box-sizing: border-box;
}

@media (max-width: 767px) {
    .cart {
        padding: 20px;
    }
}

.closeImg {
    position: absolute;
    right: 40px;
    top: 49px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

@media (max-width: 767px) {
    .closeImg {
        display: none;
    }
}

.closeImg:hover {
    opacity: 0.8;
}

.header {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 37px;
    color: #282828;
    margin-bottom: 50px;
}

@media (max-width: 767px) {
    .header {
        font-size: 20px;
        line-height: 23px;
        margin-bottom: 20px;
    }
}

.divider {
    box-sizing: border-box;
    height: 1px;
    opacity: 0.3;
    background: #929292;
}

.labelRow {
    display: flex;
    margin-top: 40px;
    margin-bottom: 20px;
}

@media (max-width: 767px) {
    .labelRow {
        margin-top: 20px;
    }
    .labelRow img {
        width: 14px;
        height: 14px;
    }
}

.label {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #282828;
    margin-left: 20px;
}

@media (max-width: 767px) {
    .label {
        font-size: 14px;
        line-height: 16px;
        margin-left: 10px;
    }
}

.formRow {
    display: flex;
    column-gap: 10px;
}

@media (max-width: 767px) {
    .formRow {
        column-gap: 5px;
    }
}

.inputName {
    padding: 21px;
    background: #FFFFFF;
    border-radius: 5px;
    width: 259px;
    height: 65px;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #282828;
    border: 0;
    outline: 0;
    box-sizing: border-box;
}

@media (max-width: 767px) {
    .inputName {
        width: 140px;
        height: 40px;
        font-size: 14px;
        line-height: 16px;
        flex-grow: 1;
        padding: 12px;
    }
}

.inputName::placeholder {
    color: #929292;
}

.selectButton {
    padding: 21px;
    background: #FFFFFF;
    border-radius: 5px;

    width: 259px;
    height: 65px;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #282828;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@media (max-width: 767px) {
    .selectButton {
        width: 280px;
        height: 40px;
        font-size: 14px;
        line-height: 16px;
        box-sizing: border-box;
        padding: 12px;
    }
}

.selectButton.selectedLeft {
    color: #D42216;
    border: 1px solid #D42216;
    border-radius: 5px 0px 0px 5px;
}

.selectButton.selectedRight {
    color: #D42216;
    border: 1px solid #D42216;
    border-radius: 0px 5px 5px 0px;
}

.selectButtonWide {
    margin-top: 10px;
    composes: selectButton;
    width: 100%;
}

.selectButtonWide.selected {
    color: #D42216;
    border: 1px solid #D42216;
    border-radius: 5px 5px 5px 5px;
}

.button {
    width: 440px;
    height: 69px;
    background: #D42216;
    border-radius: 5px;
    margin: 70px auto 0 auto;
    cursor: pointer;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    border: 0;
    outline: 0;
}

@media (max-width: 767px) {
    .button {
        width: 100%;
        height: 40px;
        font-size: 16px;
        line-height: 18px;
    }
}

.button:hover {
    background: #FA3D3D;
}

.paymentMessage {
    composes: text-info from "../../App.css";
    text-align: center;
    margin-top: 20px;
    color: #929292;
}

@media (max-width: 767px) {
    .paymentMessage {
        font-size: 14px;
        line-height: 16px;
        margin-top: 10px;
    }
}
