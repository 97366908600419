.container {
    display: grid;
    grid-template-columns: repeat(4, 290px);
    justify-content: space-between;
    background: transparent;
    max-width: 1280px;
    margin: 20px auto 0 auto;
    grid-row-gap: 40px;
    min-height: 400px;
}

@media (max-width: 1365px) {
    .container {
        grid-template-columns: 280px;
        justify-content: start;
        margin-top: 10px;
        margin-left: 20px;
        grid-row-gap: 20px;
    }
}

.container-3 {
    composes: container;
    grid-template-columns: repeat(3, 290px);
    padding: 40px 40px 0 40px;
    margin: 0;
}