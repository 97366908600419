.title {
    margin-top: 30px;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #282828;
    text-align: center;
}

.image {
    margin-top: 20px;
    width: 520px;
    height: 297px;
    border-radius: 5px;
}

.image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 767px) {
    .title {
        font-size: 14px;
        line-height: 16px;
        margin-top: 10px;
    }
    .image {
        width: 100%;
        height: 159px;
        margin-top: 10px;
        margin-bottom: 14px;

        background-image: url("../../images/map.jpg");
        background-position: 0 20%;
    }
    .image img {
        display: none;
    }
}