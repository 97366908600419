.item {
    display: flex;
    margin-bottom: 10px;
    padding: 20px 40px;
    background: #FFFFFF;
    box-shadow: 0px -6px 10px rgba(184, 184, 184, 0.1);
    border-radius: 5px;
}

@media (max-width: 767px) {
    .item {
        padding: 0 20px 0 20px;
        margin-bottom: 20px;
        height: 153px;
        position: relative;
        box-sizing: border-box;
    }
}

.divider {
    width: calc(100% - 40px);
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 20px;
    opacity: 0.3;
    background: #929292;
    display: none;
}

@media (max-width: 767px) {
    .divider {
        display: block;
    }
}

.image {
    width: 160px;
    height: 120px;
    border-radius: 5px;
}

@media (max-width: 767px) {
    .image {
        width: 135px;
        height: 105px;
    }
}

.infoColumn {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-right: -2px;
}

@media (max-width: 767px) {
    .infoColumn {
        margin-left: 10px;
    }
}

.titleRow {

}

.title {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 25px;
    color: #282828;
}

.portion {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 25px;
    color: #929292;
    margin-left: 10px;
}

@media (max-width: 767px) {
    .title, .portion {
        font-size: 14px;
        line-height: 16px;
        display: inline-block;
    }
}

.composition {
    margin-top: 10px;
}

.compositionHeader {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}

.compositionText {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    display: inline-block;
}

@media (max-width: 767px) {
    .compositionText {
        font-size: 10px;
        line-height: 14px;
    }
}

.orderRow {
    display: flex;
    align-items: center;
    margin-top: auto;
}

@media (max-width: 767px) {
    .orderRow {
        margin-bottom: 48px;
    }
}

.cost {
    font-family: 'Ubuntu', "Segoe UI";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    /* identical to box height, or 20px */

    letter-spacing: 0.04em;

    /* Чёрный */

    color: #282828;

    width: 135px;
}

@media (max-width: 767px) {
    .cost {
        font-size: 16px;
        line-height: 100%;
        /* identical to box height, or 16px */

        letter-spacing: 0.04em;
    }
}

.cartCount {
    width: 86px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #282828;

    column-gap: 17px;
    user-select: none;

    margin-left: 10px;
    background: #FAF9F9;
    border-radius: 5px;
}

@media (max-width: 767px) {
    .cartCount {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        font-size: 16px;
        line-height: 18px;
        background: white;
    }
}

.cartCountButton {
    height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

@media (hover: hover) {
    .cartCountButton:hover {
        opacity: 0.7;
    }
}