.headerWrapper {
    background: #2C2C2C;

    position: sticky;
    top: 0;
    z-index: 1000;
}

@media (max-width: 767px) {
    .headerWrapper {
        z-index: 10000;
    }
}

.header {
    max-width: 1280px;
    margin: 0 auto;

    display: flex;
    justify-content: start;
    align-items: center;
    height: 86px;
    position: relative;
}

@media (max-width: 1365px) {
    .header {
        height: 60px;
        padding-left: 20px;
    }
}

.brandImage {
    cursor: pointer;
}

.brand {
    font-family: 'Ubuntu', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    color: #D42216;

    margin-right: 43px;
    margin-top: 33.6px;
    margin-bottom: 24.4px;
    margin-left: 14px;

    cursor: pointer;
}

@media (max-width: 1365px) {
    .brand {
        margin: 20.6px 0 11.4px 14px;
        font-size: 26px;
        line-height: 30px;
    }
}


.backButton {
    display: none;
    width: 16px;
    height: 16px;
    background-image: url("../../images/backButton.svg");
    margin-bottom: 2px;
    margin-right: 10px;
}

@media (max-width: 767px) {
    .backButton {
        display: block;
    }
}

.info {
    display: flex;
    align-items: center;
    column-gap: 14px;
    margin-right: 40px;
    margin-top: 21px;
    margin-bottom: 21px;
}

@media (max-width: 1365px) {
    .info {
        display: none;
    }
}

.info img, .info a {
    width: 30px;
    height: 30px;
}

.info img:hover {
    opacity: 0.85;
    cursor: pointer;
}

.infoTextTop {
    font-family: 'Ubuntu', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: white;
    text-decoration: none;
}

.infoTextTop:hover {
    color: #D42216;
}

.infoTextBottom {
    font-family: 'Ubuntu', serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    color: white;
    margin-top: 3px;
}

.infoPayment {
    composes: info;
    margin-left: 80px;
}

.infoPaymentPopup {
    width: 446px;
    height: 140px;
    background-image: url("../../images/infoPaymentPopup.svg");
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 26px;
    box-sizing: border-box;
    color: white;
    text-align: center;
}

.navBurger {
    display: none;
    margin: 20px 20px 21px auto;
    cursor: pointer;
}

.navClose {
    display: none;
    margin: 20px 25px 21px auto;
    cursor: pointer;
}

@media (max-width: 1365px) {
    .navBurger, .navClose {
        display: block;
    }
}
