#carousel {
    max-width: 1280px;

    height: 400px;
    margin-top: 20px;
    margin-bottom: 40px;
}

@media (max-width: 1365px) {
    #carousel {
        display: none;
    }
}

#carousel .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

#carousel .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#carousel .swiper-button-prev {
    background-image: url("../../images/Trigger_Left.svg") !important;
    width: 36px;
    height: 36px;
    left: -14px;
    color: transparent;
}

#carousel .swiper-button-next {
    background-image: url("../../images/Trigger_Right.svg") !important;
    width: 36px;
    height: 36px;
    right: -14px;
    color: transparent;
}

#carousel .swiper-button-prev:hover, .swiper-button-next:hover{
    opacity: 0.8;
}