.nav {
    display: flex;
    justify-content: space-between;
    max-width: 1280px;
    margin: 34px auto 0 auto;
}

@media (max-width: 1365px) {
    .nav {
        column-gap: 10px;
        width: 100%;
        overflow: scroll;
        padding-left: 20px;
        box-sizing: border-box;
        margin-top: 20px;
        padding-bottom: 10px;
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.nav::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.nav {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
