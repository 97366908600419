.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: 80px;
    background-image: url("../../images/Footer.png");
    height: 434px;
    display: flex;
    justify-content: center;
    padding-top: 80px;
    box-sizing: border-box;
    column-gap: 120px;
}

@media (max-width: 1365px) {
    .footer {
        height: 565px;
        width: 100%;
        flex-direction: column;
        position: relative;
        background-image: url("../../images/footerMobileBg.jpg");
        padding-left: 20px;
        row-gap: 40px;
        justify-content: start;
    }
}

.col {
    position: relative;
    color: #FFFFFF
}

@media (max-width: 767px) {
    .col {
        position: static;
    }
}

.colHeader {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    color: #FFFFFF;
}

@media (max-width: 767px) {
    .colHeader {
        font-size: 16px;
        line-height: 22px;
    }
}

.colItem {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
    margin-top: 20px;
    cursor: pointer;
}

@media (max-width: 767px) {
    .colItem {
        font-size: 12px;
        line-height: 16px;
        margin-top: 10px;
    }
}

.colItem:hover {
    color: #D42216;
}

.colItemImages {
    margin-top: 20px;
    display: flex;
    column-gap: 15px;
}

@media (max-width: 767px) {
    .colItemImages {
        margin-top: 10px;
    }
}

.imageLink {
    cursor: pointer;
}

.brand {
    position: absolute;
    width: 179px;
    height: 40px;
    right: 286px;
    top: 96px;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 46px;
    color: #FFFFFF;
}

@media (max-width: 767px) {
    .brand {
        font-size: 26px;
        line-height: 30px;
        left: 20px;
        top: 10px;
    }
}

@media (min-width: 1366px) and (max-width: 1440px) {
    .brand {
        right: 250px;
    }
}

.bottom {
    height: 39px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: rgba(40, 40, 40, 0.2);
    backdrop-filter: blur(10px);
}

.bottomContent {
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
}

@media (max-width: 767px) {
    .bottom {
        left: 0;
    }
    .bottomContent {
        font-size: 14px;
        line-height: 16px;
    }
}

@media (min-width: 1366px) {
    .bottomContent {
        justify-content: space-between;
        width: 1280px;
    }
}

.modalContent {
    white-space: pre-wrap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 44px;
    color: #282828;
    margin-bottom: 40px;
}

.modalContentSubtitle {
    white-space: pre-wrap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    color: #282828;
}

.modalContentTitle {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    color: #282828;
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .modalContentTitle {
        font-size: 20px !important;
        line-height: 23px !important;
    }
    .modalContentSubtitle {
        font-size: 20px;
        line-height: 27px;
    }
    .modalContent {
        font-size: 16px;
        line-height: 22px;
    }
}

.divider {
    position: absolute;
    height: 1px;
    left: 20px;
    right: 20px;
    opacity: 0.3;
    background: #929292;
}
.line1 {
    composes: divider;
    top: 60px;
}
.line2 {
    composes: divider;
    top: 200px;
}
.line3 {
    composes: divider;
    top: 372px;
}

.madeInKodim {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
    text-decoration: none;

    display: flex;
    column-gap: 5px;
}

.madeInKodim:hover {
    opacity: 0.85;
}

.kodimLogo {
    background: url("../../images/kodimLogo.svg");
    margin-top: 2px;
    width: 70px;
    height: 12px;
}
