.wrapper {
    height: 745px;
    overflow: overlay;
    background: #161616;
    padding-bottom: 40px;
}

.cart {
    width: 1030px;
    background: #161616;
    position: relative;
    display: flex;
    flex-direction: column;
}

.closeImg {
    position: absolute;
    right: 40px;
    top: 40px;
    cursor: pointer;
}

.closeImg:hover {
    opacity: 0.8;
}

.header {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 37px;
    color: white;
    padding: 40px;
    background: #2C2C2C;
}
