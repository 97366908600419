.wrapper {
    height: 100%;
    overflow-y: auto;
    background: #FAF9F9;
}

@media (max-width: 767px) {
    .wrapper {
        width: 100vw;
        margin-top: 60px;
    }
}

.result {
    width: 600px;
    min-height: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    background: #FAF9F9;
    position: relative;
    box-sizing: border-box;
}

@media (max-width: 767px) {
    .result {
        padding: 20px;
        width: auto;
        box-sizing: border-box;
    }
}

.header {
    display: flex;
    column-gap: 20px;
    margin-bottom: 49px;
}

@media (max-width: 767px) {
    .header {
        margin-bottom: 20px;
        align-items: center;
    }
    .header img {
        width: 14px;
        height: 14px;
    }
}

.headerText {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 37px;
    color: #282828;
}

@media (max-width: 767px) {
    .headerText {
        font-size: 20px;
        line-height: 23px;
    }
}

.text {
    margin-top: 40px;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 37px;
    color: #282828;
}

@media (max-width: 767px) {
    .text {
        margin-top: 20px;
        font-size: 16px;
        line-height: 18px;
        width: 243px;
    }
}

.phoneText {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 37px;
    color: #D42216;

    margin-top: 20px;
    display: flex;
    column-gap: 20px;
    align-items: center;
}

@media (max-width: 767px) {
    .phoneText {
        margin-top: 10px;
        font-size: 16px;
        line-height: 18px;
        column-gap: 10px;
    }
    .phoneText img {
        width: 13px;
        height: 15px;
    }
}

.divider {
    box-sizing: border-box;
    height: 1px;
    opacity: 0.3;
    background: #929292;
}

@media (max-width: 767px) {
    .divider {

    }
}

.button {
    width: 440px;
    height: 69px;
    background: #D42216;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    margin: auto auto 0 auto;
}

.button img {
    transform: rotate(180deg);
}

.button:hover {
    background: #FA3D3D;
}

@media (max-width: 767px) {
    .button {
        position: static;
        margin-top: auto;
        width: 100%;
        height: 40px;
        font-size: 16px;
        line-height: 18px;
        column-gap: 10px;
    }
}